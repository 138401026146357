import LR from "../../../components/LeftRight.module.css";

import type { BillingAccountId } from "@warrenio/api-spec/spec.oats.gen";
import { LeftRight } from "../../../components/LeftRight.tsx";
import { InfoTooltip } from "../../../components/WTooltip.tsx";
import { WSelect, type ConfiguredWSelectProps } from "../../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../../components/forms/WSelectWrapper.tsx";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { BillingAccountIcon, ShortInfoSelect } from "../BillingAccountInfo.tsx";
import { eBillingAccountQueryAtom } from "../billingAccountQuery.ts";
import { getDefaultAccount, type EBillingAccount } from "../billingLogic.tsx";

export interface BillingSelectProps extends ConfiguredWSelectProps<EBillingAccount, BillingAccountId> {
    allowClosedAccounts?: boolean;
    /** Only show title (no icon, auxiliary info on right) */
    compact?: boolean;
    isReferral?: boolean;
}

export function BillingSelectCustom({
    allowClosedAccounts,
    isItemDisabled,
    compact,
    isReferral,
    ...props
}: BillingSelectProps) {
    return (
        <WSelect
            isReferral={isReferral}
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select billing account"
            dynamicDefault={(items) => getDefaultAccount(items)}
            getKey={(item) => item.id}
            renderUnknown={() => (
                <InfoTooltip text="The billing account has been closed and is no longer available.">N/A</InfoTooltip>
            )}
            getTextValue={(item) => item.title}
            searchItems
            {...props}
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            isItemDisabled={(item) => isItemDisabled?.(item) || (!allowClosedAccounts && !item.isOpen)}
        >
            {(item) => (
                <LeftRight
                    icon={!compact && <BillingAccountIcon account={item} />}
                    title={item.title}
                    right={!compact && <ShortInfoSelect account={item} />}
                />
            )}
        </WSelect>
    );
}

export type BillingSelectLoaderProps = Omit<BillingSelectProps, "items">;

function BillingSelectLoader(props: BillingSelectLoaderProps) {
    const data = useSuspenseQueryAtom(eBillingAccountQueryAtom);
    const currentAccount = props.valueKey ? data.get(props.valueKey) : undefined;

    return <BillingSelectCustom value={currentAccount} items={[...data.values()]} {...props} />;
}

export function BillingSelect(props: BillingSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <BillingSelectCustom {...props} {...renderProps} />}>
            <BillingSelectLoader {...props} />
        </WSelectWrapper>
    );
}
